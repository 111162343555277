<template>
	<div>
		<div>
			<v-btn v-if="isText" color="success" @click="showSignaturesDialog = true">
				Signatures
			</v-btn>

			<v-btn
				v-else
				@click="showSignaturesDialog = true"
				:color="'success'"
				large
				icon
			>
				<v-icon large>
					mdi-draw
				</v-icon>
			</v-btn>
		</div>
		<v-dialog
			v-if="showSignaturesDialog"
			v-model="showSignaturesDialog"
			persistent
			max-width="800"
		>
			<v-card>
				<v-card-title class="text-h5 transparent-3">
					{{ user.name }} Signatures
					<v-spacer></v-spacer>

					<!-- <v-btn
						color="success"
						icon
						@click="createUpdateSignatureDialogShow()"
					>
						<v-icon>mdi-pencil</v-icon>
					</v-btn> -->

					<v-btn color="error" icon @click="showSignaturesDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="my-3">
					<v-row>
						<v-col cols="3">Details</v-col>
						<v-col cols="3">Short Code </v-col>
						<v-col cols="4">Body</v-col>
						<v-col cols="2">Actions</v-col>
					</v-row>
					<v-row v-for="(signature, index) in signatures" :key="index">
						<v-col class="text-capitalize" cols="3">
							<span>{{ signature.payload.name }} </span>
							<span>{{ signature.payload.communication_type }} </span>
							<span>{{ signature.payload.application_type }} </span>
						</v-col>
						<v-col cols="3"> {{ `${signature.payload.short_code}` }} </v-col>
						<v-col cols="4" v-html="signature.payload.body"> </v-col>
						<v-col cols="2">
							<v-btn
								color="success"
								icon
								@click="createUpdateSignatureDialogShow(signature)"
							>
								<v-icon>mdi-update</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="transparent-2">
					<v-btn
						color="green darken-1"
						outlined
						block
						@click="showSignaturesDialog = false"
					>
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<template v-if="createUpdateSignatureDialog">
			<createUpdateSignatureDialog
				@refresh="refresh()"
				@closeDialog="createUpdateSignatureDialogClose()"
				:dialogModel="createUpdateSignatureDialog"
				:selectedModel="selectedSignature"
				:user="user"
			/>
		</template>
	</div>
</template>

<script>
	import createUpdateSignatureDialog from "@/components/dialogs/createUpdateSignatureDialog";

	export default {
		components: { createUpdateSignatureDialog },
		props: {
			user: { required: true, default: null },
			isText: { required: false, default: true }
		},
		data() {
			return {
				signatures: [],
				showSignaturesDialog: false,
				createUpdateSignatureDialog: false,
				selectedSignature: null,
				isValidForm: false
			};
		},
		mounted() {
			this.getAgentSignatures();
		},
		computed: {},
		watch: {
			showSignaturesDialog(newVal) {
				if (newVal) this.getAgentSignatures();
			}
		},
		methods: {
			refresh() {
				this.getAgentSignatures();
				this.$emit("refresh");
			},
			createUpdateSignatureDialogShow(signature) {
				this.selectedSignature = signature;
				this.createUpdateSignatureDialog = true;
			},
			createUpdateSignatureDialogClose() {
				this.selectedSignature = null;
				this.createUpdateSignatureDialog = false;
			},
			async getAgentSignatures() {
				let data = {
					agent_id: this.user?.agent?.id
				};

				return await this.post(
					`${this.baseUrl}/agent-portal/get-signatures`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					// this.showMessageFromResponseDta(data);
					if (data.code == 200) {
						this.signatures = data.data.signatures;
					}
				});
			}
		}
	};
</script>

